<template>
  <div>
    <v-form ref="form" class="multi-col-validation mt-6">
      <v-card
        flat
      >
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">DISTRICT/CLUB DETAILS </h4>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-col
            md="4"
            cols="12"
          ></v-col>
          <v-row>
            <v-col
              md="4"
              cols="12"
            >
              <v-text-field
                v-model="date_started"
                label="Date Started"
                type="date"
                dense
                outlined
                :rules="rules.default_max_45_character_and_no_empty_rule"
              ></v-text-field>
            </v-col>
            <v-col
              md="12"
              cols="12"
            >
              <v-text-field
                v-model="branch_code"
                label="District/Club Name"
                dense
                outlined
                :rules="rules.default_max_100_character_and_no_empty_rule"
              ></v-text-field>
            </v-col>
            <v-col
              md="12"
              cols="12"
            >
              <v-text-field
                v-model="address"
                label="Address"
                dense
                outlined
                :rules="rules.default_max_255_character_and_no_empty_rule"
              ></v-text-field>
            </v-col>
            <v-col
              md="4"
              cols="12"
              v-if="is_club"
            >
              <v-select
                v-model="type_of_club"
                dense
                outlined
                label="Type of Club"
                :items="['Groups','Livelihood','SPP','SFAP','Feeding']"
                :rules="rules.combobox_rule"
              ></v-select>
            </v-col>
          </v-row>
          <v-checkbox
            v-model="is_club"
            label="is Club?"
            class="mt-0"
            hide-details
          ></v-checkbox>
          <v-checkbox
            v-model="is_sector"
            label="is SECTOR?"
            class="mt-0"
            hide-details
          ></v-checkbox>
        </v-card-text>
      </v-card>

      <v-col cols="12" v-show="alert">
        <v-alert
          color="warning"
          text
          class="mb-0"

        >
          <div class="d-flex align-start">
            <v-icon color="warning">
              {{ icons.mdiAlertOutline }}
            </v-icon>

            <div class="ms-3">
              <p class="text-base font-weight-medium mb-1">
                {{alert_message}}
              </p>
            </div>
          </div>
        </v-alert>
      </v-col>

      <v-col cols="12">
        <v-btn
          color="primary"
          class="me-3 mt-4"
          @click="save_branch_details"
          v-if="!saving"
        >
          Save changes
        </v-btn>
        <v-progress-circular
          :size=50
          :width="5"
          color="primary"
          indeterminate
          v-else
        ></v-progress-circular>
      </v-col>
    </v-form>
    <v-simple-table dense>
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-uppercase">
            Date Started
          </th>
          <th class="text-center text-uppercase">
            Registered District/Club
          </th>
          <th class="text-center text-uppercase">
            Category
          </th>
          <th class="text-center text-uppercase">
            Status
          </th>
          <th class="text-center text-uppercase" v-if="position==='ADMIN'">
            Action
          </th>
          <th class="text-center text-uppercase" v-if="position==='ADMIN'">
            Assign Officer/Member
          </th>
          <th class="text-center text-uppercase" v-if="position==='ADMIN'">
            Edit Details
          </th>
        </tr>
        </thead>
        <tbody>
        <tr
          v-for="item in bank_items"
          :key="item.id"
        >
          <td>{{ item.date_established }}</td>
          <td class="text-center">
            {{ item.district_name }}
          </td>
          <td class="text-center">
            <div v-if="item.is_club===1">
              <v-chip color="warning" dark>CLUB</v-chip>
            </div>

            <div v-else-if="item.is_sector===1">
              <v-chip color="primary" dark>SECTOR</v-chip>
            </div>
            <div v-else-if="item.is_club===0 && item.is_sector===0">
              <v-chip color="info"  dark>DISTRICT</v-chip>
            </div>
          </td>
          <td class="text-center">
            <div v-if="item.is_active===1">
              <v-chip color="error" dark>DEACTIVATED</v-chip>
            </div>
            <div v-else-if="item.is_active===0">
              <v-chip color="success" dark>ACTIVE</v-chip>
            </div>
          </td>
          <td class="text-center" v-if="position==='ADMIN'">
            <div v-if="item.is_active===1">
              <v-icon
                class="mr-2"
                color="error"
                @click="actions(item,0)"
              >
                {{icons.mdiToggleSwitchOff}}
              </v-icon>
            </div>
            <div v-else-if="item.is_active===0">
              <v-icon
                class="mr-2"
                color="success"
                @click="actions(item,1)"
              >
                {{icons.mdiToggleSwitch}}
              </v-icon>
            </div>
          </td>
          <td class="text-center" v-if="position==='ADMIN'">
            <v-icon
              class="mr-2"
              color="error"
              @click="update_officers(item.district_name,item.officers,item.id)"
            >
              {{icons.mdiLeadPencil}}
            </v-icon>
          </td>
          <td class="text-center" v-if="position==='ADMIN'">
            <v-icon
              class="mr-2"
              color="warning"
              @click="edit_details(item)"
            >
              {{icons.mdiFileEdit}}
            </v-icon>
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-dialog v-model="edit_dialog" max-width="80%" :key="this.key">
      <v-card>
        <v-form ref="form" class="multi-col-validation mt-6">
          <v-card
            flat
          >
            <v-toolbar dense dark color="primary">
              <v-toolbar-title><h4 class="font-weight-light">DISTRICT/CLUB DETAILS </h4>
              </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-col
                md="4"
                cols="12"
              ></v-col>
              <v-row>
                <v-col
                  md="4"
                  cols="12"
                >
                  <v-text-field
                    v-model="date_started"
                    label="Date Started"
                    type="date"
                    dense
                    outlined
                    :rules="rules.default_max_45_character_and_no_empty_rule"
                  ></v-text-field>
                </v-col>
                <v-col
                  md="12"
                  cols="12"
                >
                  <v-text-field
                    v-model="branch_code"
                    label="District/Club Name"
                    dense
                    outlined
                    :rules="rules.default_max_100_character_and_no_empty_rule"
                  ></v-text-field>
                </v-col>
                <v-col
                  md="12"
                  cols="12"
                >
                  <v-text-field
                    v-model="address"
                    label="Address"
                    dense
                    outlined
                    :rules="rules.default_max_255_character_and_no_empty_rule"
                  ></v-text-field>
                </v-col>
                <v-col
                  md="4"
                  cols="12"
                  v-if="is_club"
                >
                  <v-select
                    v-model="type_of_club"
                    dense
                    outlined
                    label="Type of Club"
                    :items="['Livelihood','SPP','SFAP','Feeding']"
                    :rules="rules.combobox_rule"
                  ></v-select>
                </v-col>
              </v-row>
              <v-checkbox
                v-model="is_club"
                label="is Club?"
                class="mt-0"
                hide-details
                disabled
              ></v-checkbox>
            </v-card-text>
          </v-card>

          <v-col cols="12" v-show="alert">
            <v-alert
              color="warning"
              text
              class="mb-0"

            >
              <div class="d-flex align-start">
                <v-icon color="warning">
                  {{ icons.mdiAlertOutline }}
                </v-icon>

                <div class="ms-3">
                  <p class="text-base font-weight-medium mb-1">
                    {{alert_message}}
                  </p>
                </div>
              </div>
            </v-alert>
          </v-col>

          <v-col cols="12">
            <v-btn
              color="primary"
              class="me-3 mt-4"
              @click="update_branch_details"
              v-if="!saving"
            >
              Update changes
            </v-btn>
            <v-progress-circular
              :size=50
              :width="5"
              color="primary"
              indeterminate
              v-else
            ></v-progress-circular>
          </v-col>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="officers_dialog" persistent max-width="80%">
      <new-officers-in-district
        :key="this.key"
        :name_of_district="name_of_district"
        :district_id="district_id"
        :can_edit="true"
      ></new-officers-in-district>
      <v-btn color="error" @click="officers_dialog = false"> Close</v-btn>
    </v-dialog>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {
    mdiAlertOutline,
    mdiCloudUploadOutline,
    mdiToggleSwitch,
    mdiToggleSwitchOff,
    mdiLeadPencil,
    mdiFileEdit,
  } from '@mdi/js'
  import {mapGetters, mapActions} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
  import NewOfficersInDistrict from '@/views/admin_interface/NewOfficersInDistrict'

  const initialState = () => {
    return {
      edit_dialog: false,
      officers_dialog: false,
      saving: false,
      alert: false,
      alert_message: '',

      branch_code: '',
      date_started: '',
      is_club: false,
      is_sector: false,
      bank_items: [],

      name_of_district: '',
      district_id: 0,
      key: 0,
      address: '',
      type_of_club: '',
    }
  }
  export default {
    components: {
      snackBarDialog,
      NewOfficersInDistrict
    },
    setup() {
      return {
        icons: {
          mdiAlertOutline,
          mdiCloudUploadOutline,
          mdiToggleSwitchOff,
          mdiToggleSwitch,
          mdiLeadPencil,
          mdiFileEdit,
        },
      }
    },
    data() {
      return initialState()
    },
    watch: {
      edit_dialog: function () {
        if (!this.edit_dialog) {
          this.reset()
        }
      }
    },
    mounted() {
      this.load_data()
    },
    computed: {
      ...mapGetters('authentication', ['position']),
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('district', ['save_new_district_information', 'list_of_district', 'change_status', 'update_district_information']),
      update_officers(name_of_district, officers, id) {
        this.district_id = id
        this.officers_dialog = true
        this.name_of_district = name_of_district
        this.key++
      },
      edit_details(data) {
        this.edit_dialog = true
        this.district_id = data.id
        this.key++
        this.date_started = data.date_established
        this.branch_code = data.district_name
        this.address = data.address
        this.type_of_club = data.type_of_club
        this.is_club = data.is_club
        this.is_sector = data.is_sector
      },
      reset() {
        Object.assign(this.$data, initialState())
        this.load_data()
      },
      save_branch_details() {
        this.saving = true
        this.alert = false
        if (this.$refs.form.validate()) {
          const data = new FormData()
          data.append('district_name', this.branch_code.toUpperCase());
          data.append('date_established', this.date_started);
          data.append('address', this.address.toUpperCase());
          data.append('type_of_club', this.type_of_club);
          var club = 0
          var sector = 0
          if (this.is_club) {
            club = 1
          }
          if (this.is_sector) {
            club = 1
          }
          data.append('is_club', club);
          data.append('is_sector', sector);
          this.save_new_district_information(data)
            .then(response => {
              this.change_snackbar({
                show: true,
                color: 'success',
                text: response.data,
              })
              this.reset()
            })
            .catch(error => {
              this.alert = true
              this.alert_message = error
              this.saving = false
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving = false
        }
      },
      update_branch_details() {
        this.saving = true
        this.alert = false
        if (this.$refs.form.validate()) {
          const data = new FormData()
          data.append('id', this.district_id);
          data.append('district_name', this.branch_code.toUpperCase());
          data.append('date_established', this.date_started);
          data.append('address', this.address.toUpperCase());
          data.append('type_of_club', this.type_of_club);
          var club = 0
          if (this.is_club) {
            club = 1
          }
          data.append('is_club', club);
          this.update_district_information(data)
            .then(response => {
              this.change_snackbar({
                show: true,
                color: 'success',
                text: response.data,
              })
              this.reset()
            })
            .catch(error => {
              this.alert = true
              this.alert_message = error
              this.saving = false
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving = false
        }
      },
      load_data() {
        this.list_of_district()
          .then(response => {
            this.bank_items = response.data
          })
      },
      actions(item, status) {
        const data = new FormData()
        data.append('id', item.id);
        data.append('status', status);
        data.append('district_name', item.district_name);
        this.change_status(data)
          .then(response => {
            if (status === 1) {
              this.change_snackbar({
                show: true,
                color: 'error',
                text: response.data,
              })
            } else {
              this.change_snackbar({
                show: true,
                color: 'success',
                text: response.data,
              })
            }
            this.load_data()
          })
          .catch(error => {
            console.log(error)
          })
      },
    }
  }
</script>
